import { formatDistance, formatTimezoneDate } from '@/lib/formatter';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RunType } from '../../interfaces/inspection.interfaces';
import SettingsStore from 'app/modules/settings/settings.context';
import ConfirmationModal from '../confirmation-modal/confirmation-modal';
import EditRunModal from '../edit-run-modal/editRunModal';
import ProgressBar from '../progress-bar/progress-bar';
import SecondaryButton from '../secondary-button/secondary-button';
import {
  Card,
  RunCompletePercentage,
  WeatherInformation,
  Temperature,
  WeatherText,
  Actions,
  MenuButton,
  MenuListWrapper,
  MenuListItem,
  ScheduledLabel,
  LineStatText,
  FooterWrapper,
  ProgressBarContainer,
  PipelineLength,
  InfoContent,
} from './run-card.style';

interface CardProps {
  customStyle?: string;
  run: RunType;
  hideMenu?: boolean;
  usingLineStat: boolean;
  usingQube: boolean;
  onUpdated?: () => void;
  onDelete?: () => void;
}

/**
 *
 * @returns
 */
const RunCard = ({
  customStyle,
  run,
  usingLineStat,
  usingQube,
  hideMenu,
  onUpdated,
  onDelete,
}: CardProps) => {
  const history = useHistory();
  const settingsContext = useContext(SettingsStore);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<any>({});

  const progressDone: number = Math.ceil(
    ((run.completed_points || 0) / (run.total_points || 0)) * 100
  );

  const units = {
    ft: 'mi',
    mi: 'mi',
    m: 'km',
    km: 'km',
  };

  return (
    <Card
      id={`run-card-${run.id}`}
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/runs/${run.id}`);
      }}
      customStyle={customStyle}
    >
      <header>
        <div style={{ display: 'grid', justifyContent: 'start' }}>
          <SecondaryButton
            customStyle={`
              font-family: Inter;
              font-style: normal;
              font-size: 12px;
              font-weight: 500;
              overflow: hidden;
              max-width: 100%;
              text-overflow: ellipsis;
              white-space: nowrap;

              color: #1a1e36;

              @media (min-width: 1024px) {
                font-size: 14px;
              }
            `}
            handleClick={(e) => {
              e.stopPropagation();
              history.push(`/runs/${run.id}`);
            }}
          >
            {run.name}
          </SecondaryButton>
        </div>

        <InfoContent>
          {!run.is_finished && run.scheduled_launch_at ? (
            <ScheduledLabel>
              <strong>Scheduled to:</strong>{' '}
              {formatTimezoneDate({
                date: `${run?.scheduled_launch_at}`,
                timezone: settingsContext.state.timezone?.id || '',
                format: 'MM/DD HH:mm:ss',
              })}
            </ScheduledLabel>
          ) : null}

          {run.is_finished && run.receive_time ? (
            <ScheduledLabel>
              <strong>Finished on:</strong>{' '}
              {formatTimezoneDate({
                date: `${run?.receive_time}`,
                timezone: settingsContext.state.timezone?.id || '',
                format: 'MM/DD HH:mm:ss',
              })}
            </ScheduledLabel>
          ) : null}

          <PipelineLength>
            <strong>Total Length:</strong>{' '}
            {formatDistance({
              distance: run.last_chainage || run.length,
              unit: units[
                (settingsContext.state.distanceUnit?.id || 'ft') as 'ft' | 'mi' | 'm' | 'km'
              ],
            })}
          </PipelineLength>
        </InfoContent>
      </header>
      <ProgressBarContainer>
        <ProgressBar
          currentPercentage={!isNaN(progressDone) ? progressDone || 0 : 0}
        />
        <RunCompletePercentage>
          Run is {progressDone}% complete ({run.completed_points} of{' '}
          {run.total_points})
        </RunCompletePercentage>
      </ProgressBarContainer>
      <footer>
        <FooterWrapper>
          {run.forecast ? (
            <WeatherInformation>
              <img
                alt=""
                src={`/assets/icons/weather/${run.forecast?.icon || 'sunny.png'
                  }`}
              />
              <Temperature>
                {parseInt(run.forecast.feelslikeF, 10)}°F
              </Temperature>
              <WeatherText>{run.forecast?.weather}</WeatherText>
            </WeatherInformation>
          ) : null}
        </FooterWrapper>
        <Actions columns={hideMenu ? 1 : 2}>
          <SecondaryButton
            customStyle={`
              display: none;

              @media (min-width: 1024px) {
                display: block;
              }
            `}
            handleClick={(e) => {
              e.stopPropagation();
              history.push(`/runs/${run.id}/report`);
            }}
          >
            Report
          </SecondaryButton>
          <MenuButton
            id="run-card-menu"
            hide={!!hideMenu}
            onClick={(e: any) => {
              e.stopPropagation();
              setShowMenu(!showMenu);
            }}
          >
            <svg
              width="16"
              height="4"
              viewBox="0 0 16 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2" cy="2" r="2" fill="#8E949F" />
              <circle cx="8" cy="2" r="2" fill="#8E949F" />
              <circle cx="14" cy="2" r="2" fill="#8E949F" />
            </svg>
            <MenuListWrapper hide={!showMenu}>
              <MenuListItem id="run-card-edit" onClick={() => setEditModal(true)}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 1id5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 4L2 9L2 13H6L11 8M7 4L10 1L14 5L11 8M7 4L11 8"
                    stroke="#8C929D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M7 4L11 8" stroke="#8C929D" strokeWidth="2" />
                </svg>
                Edit
              </MenuListItem>
              <MenuListItem
                id="run-card-delete"
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setConfirmationModal({
                    hide: false,
                    title: 'Are you sure?',
                    text: 'This run and all related data will be deleted permanently.',
                    onConfirm: (e: any) => {
                      e.stopPropagation();
                      if (onDelete) onDelete();
                    },
                    onCancel: (e: any) => {
                      e.stopPropagation();
                      setConfirmationModal({});
                    },
                  });
                }}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.6667 2.33333H17M15.6667 2.33333V14.3333C15.6667 15.8061 14.4728 17 13 17H5C3.52724 17 2.33333 15.8061 2.33333 14.3333V2.33333M15.6667 2.33333H11.6667M1 2.33333H2.33333M2.33333 2.33333H6.33333M11.6667 2.33333C11.6667 1.59695 11.0697 1 10.3333 1H7.66667C6.93029 1 6.33333 1.59695 6.33333 2.33333M11.6667 2.33333H6.33333M9 11.6667V7.66667"
                    stroke="#8C929D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Delete
              </MenuListItem>
            </MenuListWrapper>
          </MenuButton>
        </Actions>

        {usingLineStat ? <LineStatText>Using LineStat</LineStatText> : null}
        {usingQube ? <LineStatText>Using Qube</LineStatText> : null}
      </footer>
      <EditRunModal
        hide={!editModal}
        onClose={() => setEditModal(!editModal)}
        run={run}
        onUpdated={onUpdated}
      />
      <ConfirmationModal id={`confirmation-modal-${run.id}`} {...confirmationModal} />
    </Card>
  );
};

export default RunCard;
